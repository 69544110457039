import { type Directive } from "vue"
import { useUserStore } from '@/store/user';
import {useRouter, useRoute} from "vue-router";
import {router } from "@/route";

const userStore = useUserStore();


/** 下拉加载指令 */
const action: Directive = {
    mounted(el: any, binding, vnode) {
        // console.log(el)
        // console.log(binding)
        // console.log('vnode', vnode)
        const actionName = binding.arg
        const permissions = userStore.permissions
        // console.log('router', router)
        const elVal = router.currentRoute.value.meta.permission
        const permissionId = elVal instanceof String && [elVal] || elVal
        permissions?.forEach(p =>{
            if (permissionId !== p.permissionId) {
                return
            }
            if ((p.actionList && !p.actionList.includes(actionName)) || !p.actionList) {
                el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
            }
        })
    }
}


export default action