
import { Directive, DirectiveBinding, nextTick } from 'vue'

const loadMore: Directive = {
	beforeMount(el: any, binding: DirectiveBinding) {
		const selectDom = (document.querySelector(".more_select_dropdown") as any).querySelector(".el-select-dropdown .el-select-dropdown__wrap");
		function loadMores(this: any) {
			const isBase = this.scrollHeight - this.scrollTop <= Math.max(this.clientHeight, this.scrollHeight * 0.3)
			if (isBase) {
				binding.value && binding.value()
			}
		}
		el.selectDomInfo = selectDom
		el.userLoadMore = loadMores
		nextTick(() => {
			selectDom?.addEventListener('scroll', loadMores)
		})
	},
	beforeUnmount(el: any) {
		if (el.userLoadMore) {
			el.selectDomInfo.removeEventListener('scroll', el.userLoadMore)
			delete el.selectDomInfo
			delete el.userLoadMore
		}
	}
}


export default loadMore