import { defineStore } from 'pinia';

export interface EnvState {
    env?: string;
    usingAPI?: string;
}

export const useEnvStore = defineStore('env', {
    state: (): EnvState => ({
        env: '',
        usingAPI: '',
    }),
    getters: {
        GET_ENV(): string {
            return this.usingAPI || this.env || '';
        },
    },
    actions: {
        INIT_ENV(value) {
            if (!this.env) {
                this.env = value
            }
            if (value === 'development') {
                const usingAPI = localStorage.getItem('usingAPI');
                usingAPI &&this.SET_USINGAPI(usingAPI);
            }
        },
        SET_USINGAPI(value) {
            if (value === 'development' || value === 'local') {
                localStorage.removeItem('usingAPI');
                localStorage.setItem('usingAPI', value);
                this.usingAPI = value;
            }
        },
    },
});
