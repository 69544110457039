import  api  from "../axios";
import { useUserStore } from "../store/user"
import { router } from "@/route";

export function logout(withApi = true) {
    const store = useUserStore();
    const logoutUrl = 'system/login/logout';
    if (withApi) {
        api.post({ url: logoutUrl }).then(() => {
            store.logout();
            router.push("/login");
        })
    } else {
        store.logout();
        router.push("/login");
    }
}