import { PropType } from 'vue';

export const RankingItemProps = {
    platenumber: {
        type: String,
        require: true,
    },
    dynamicMileage: {
        type: Number,
        require: true,
    },
    max: {
        type: Number,
        require: true,
    },
    sort: {
        type: Number,
        require: true,
    }
}

export const RankingListProps = {
    topVehicle: {
        type: Array as PropType<{
            dynamicMileage: string,
            platenumber: string
        }[]>
    },
}

export const statisticsProps = {
    OFF: {
        type: Number,
        require: true,
    },
    OTHER: {
        type: Number,
        require: true,
    },
    QUIET: {
        type: Number,
        require: true,
    },
    TOTAL: {
        type: Number,
        require: true,
    },
    TRAVEL: {
        type: Number,
        require: true,
    },
    ARRIVETIME: {
        type: String,
        require: true,
    },
    DYNAMIC_MILEAGE: {
        type: Number,
        require: true,
    },
}