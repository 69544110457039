export const rankingStyle = [{
    backgroundColor: '#ffee55',
    filter: '#c2b35d',
    color: '#867c42',
}, {
    backgroundColor: '#cce8ec',
    filter: '#ebebeb',
    color: '#4b5253',
}, {
    backgroundColor: '#d88c68',
    filter: '#ec9572',
    color: '#7a341e',
}, {
    backgroundColor: '#418cee',
    filter: '#62b2fd',
    color: '#dadada',
}]