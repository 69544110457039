import { createRouter, createWebHistory, RouteRecordRaw, Router } from 'vue-router';
import { store } from '../store';
import  api  from "../axios";
import { useUserStore } from '../store/user';
import config from './config';
import { logout } from "@/utils/logout";

const userStore = useUserStore(store);
userStore.LOAD_LOCAL_STORAGE();

const routes: Array<RouteRecordRaw> = config;

const whiteList = ["/login"];
const staticList = ["/app", "/appDownload", "/privacyPolicy"];

export const router: Router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise(resolve => {
            if (savedPosition) {
                return savedPosition;
            } else {
                if (from.meta.saveSrollTop) {
                    const top: number =
                        document.documentElement.scrollTop || document.body.scrollTop;
                    resolve({ left: 0, top });
                }
            }
        });
    }
})

const onloadAsyncRoutes = (routerMap, parent?) => {
    if (!routerMap) return []
    return routerMap.map(item => {
        const { title, icon, keepAlive } = item.meta || {};
        const isSubMenu = item.children && item.children.length > 0
        const currentRouter = {
            path: `${parent && parent.path || ''}/${item.path}`,
            name: item.name || item.key || '',
            component: isSubMenu ? '' : (() => import(`@/view/${item.component}`)),
            children: [],
            meta: {
                title: title,
                icon: icon || undefined,
                permission: item.name,
                keepAlive: keepAlive === false ? false : true,
            }
        };
        // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
        if (!currentRouter.path.startsWith('http')) {
            currentRouter.path = currentRouter.path.replace('//', '/')
        }
        // 是否有子菜单，并递归处理
        if (isSubMenu) {
            // Recursion
            currentRouter.children = onloadAsyncRoutes(item.children, currentRouter)
        }
        return currentRouter
    })
}

export function handleAsyncRoutes(data) {
    //todo
    //读取接口路由
    const routerRaw = onloadAsyncRoutes(data.children);
    routerRaw.map((raw) => {
        router.addRoute('Layout', raw);
    });
    router.addRoute({
        path: "/:pathMatch(.*)",
        name: "pathMatch",
        redirect: "/error/404"
    })
}

export async function initRouter() {
    const urlGetMenu = '/system/menu/getListMenuByRoleId';
    const urlGetPermissions = '/system/getUserInForAndButtonPermissions';
    /** 读取用户角色权限 */
    await api.post({ url: urlGetPermissions }).then(res => {
        const { data } = res;
        data?.role?.permissions.map(per => {
            if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                const action = per.actionEntitySet.map(action => { return action.action })
                per.actionList = action
            }
        })

        userStore.setUserState({
            key: 'permissions',
            value: data?.role?.permissions
        });
        userStore.setUserState({
            key: 'userName',
            value: data?.name
        });
        userStore.setUserState({
            key: 'avatar',
            value: data?.avatar
        });
    }).catch((e) => {
        logout(false);
        //router.push("/login");
    })

    await api.post({ url: urlGetMenu }).then(res => {
        const { data } = res;
        handleAsyncRoutes({ ...data});
        userStore.setUserState({
            key: 'userMenu',
            value: data
        });
    }).catch((e) => {
        logout(false);
        //router.push("/login");
    })
    return new Promise<Router>((resolve) => {
        /** 读取用户菜单权限 */
        resolve(router);
    });
}

router.beforeEach((to, from, next) => {
    // console.log(to, from);
    to.matched.some(item => {
        if (!item.meta.title) return "";
        else document.title = `易管车 - ${item.meta.title as string}`;
    });

    /** 如果已经登录并存在登录信息后不能跳转到路由白名单，而是继续保持在当前页面 */
    function toCorrectRoute() {
        whiteList.includes(to.fullPath) 
            ? next(from.fullPath) 
            : next()
    }
    if (userStore.isLogin) {
        // 无权限跳转403页面
        if (to.meta?.permission && userStore.getPermissionsList && !~userStore.getPermissionsList.indexOf(to.meta?.permission as string)) {
            next({ path: "/error/403" });
        }
        // 刷新
        if (!from?.name && to.path !== "/login" && userStore.permissions && userStore.permissions.length === 0) {
            initRouter().then((router: Router) => {
                router.push(to.fullPath);
            });
        }
        toCorrectRoute();
    } else {
        if (to.path !== "/login") {
            if (whiteList.indexOf(to.path) !== -1 || staticList.indexOf(to.path) !== -1) {
                next();
            } else {
                next({ path: "/login" });
            }
        } else {
            next();
        }
    }
});