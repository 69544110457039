import { RouteRecordRaw } from 'vue-router'
import Login from '../view/Login/index.vue'
import Layout from '../layout/index.vue'
import Home from '../view/Home/index.vue'
import AppDownload from '@/view/AppDownload/index.vue'
import PrivacyPolicy from '@/view/Other/privacyPolicy.vue'
import Redirect from '../layout/redirect.vue'

const staticRouter: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Layout',
        redirect: "/home",
        component: Layout,
        children: [
            {
                path: "/home",
                name: "HomePage",
                component: Home,
                meta: {
                    title: "首页",
                }
            },
            {
                path: '/MenuManagement',
                name: 'MenuManagement',
                meta: {
                    title: '菜单管理',
                },
                component: () => import(/* webpackChunkName: "charts" */ '@/view/System/MenuManagement/MenuManagement.vue'),
            },
            {
                path: '/FocusTrack',
                name: 'FocusTrack',
                meta: {
                    title: '轨迹跟踪',
                },
                component: () => import(/* webpackChunkName: "charts" */ '@/view/Map/FocusMonitor/index.vue'),
            },
        ]
    }, {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: "登录",
        }
    }, {
        path: '/redirect',
        name: 'Redirect',
        component: Layout,
        children: [
            {
                path: "/redirect/:path(.*)",
                name: "Redirect",
                component: Redirect,
                meta: {
                    title: "首页",
                }
            }
        ]
    }, {
        path: '/error',
        redirect: '/error/403',
        children: [
            {
                path: "/error/403",
                name: "403",
                component: () => import("@/view/Error/403.vue"),
                meta: {
                    title: "403",
                }
            }, {
                path: "/error/404",
                name: "404",
                component: () => import("@/view/Error/404.vue"),
                meta: {
                    title: "404",
                }
            },
        ]
    }, {
        path: '/appDownload',
        name: 'AppDownload',
        meta: {
            title: 'APP下载页',
        },
        component: AppDownload,
    },{
        path: '/app',
        name: 'App',
        meta: {
            title: 'APP下载',
        },
        component: AppDownload,
    }, {
        path: '/privacyPolicy',
        name: 'PrivacyPolicy',
        meta: {
            title: '隐私政策',
        },
        component: PrivacyPolicy,
    },
]

export default staticRouter