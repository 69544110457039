import { reactive } from "vue";
import type { FormRules } from "element-plus";

/** 密码正则（密码格式应为8-18位数字、字母、符号的任意两种组合） */
export const REGEXP_PWD =
    /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[()])+$)(?!^.*[\u4E00-\u9FA5].*$)([^(0-9a-zA-Z)]|[()]|[a-z]|[A-Z]|[0-9]){8,18}$/;
/** 简单密码正则（密码格式应为8-18位数字、字母组合） */
export const SIMPLE_REGEXP_PWD =
    /^(?=.*\d)(?=.*[a-zA-Z]).{6,18}$/;


/** 登录校验 */
const loginRules = reactive(<FormRules>{
    password: [
        {
            validator: (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入密码"));
                } else if (value.length < 6 || value.length > 18) {
                    callback(
                        new Error("密码格式应为6-18位")
                    );
                } else if (!SIMPLE_REGEXP_PWD.test(value)) {
                    callback(
                        new Error("密码必须包含数字和字母")
                    );
                } else {
                    callback();
                }
            },
            trigger: "blur"
        }
    ]
});

export { loginRules };
