

const defaultUrl = 'https://api.map.baidu.com/library/$name/$version/src/$name_min.js';
const defaultCssUrl = 'https://api.map.baidu.com/library/$name/$version/src/$name_min.css';
//const defaultUrl = 'http://api.map.baidu.com/library/$name/$version/docs/symbols/src/BMapLib_$name.js.html'

const loadLibInfo = {
    TEXTICONOVERLAY: {
        version: '1.2',
        cssVersion: '',
        name: 'TextIconOverlay',
    },
    LUSHU: {
        version: '1.2',
        cssVersion: '',
        name: 'LuShu',
    },
    CURVELINE: {
        version: '1.5',
        cssVersion: '',
        name: 'CurveLine',
        scriptUrl: 'http://api.map.baidu.com/library/CurveLine/1.5/src/CurveLine.min.js'
    },
    MARKERCLUSTERER: {
        version: '1.2',
        cssVersion: '',
        name: 'MarkerClusterer',
    },
    DRAWINGMANAGER: {
        version: '1.4',
        cssVersion: '',
        name: 'DrawingManager',
    },
    HEATMAP: {
        version: '2.0',
        cssVersion: '',
        name: 'Heatmap ',
    },
    TRAFFICCONTROL: {
        version: '1.4',
        cssVersion: '1.4',
        name: 'TrafficControl ',
    },
    GEOUTILS: {
        version: '1.2',
        cssVersion: '',
        name: 'GeoUtils ',
    },
    RICHMARKER: {
        version: '1.2',
        cssVersion: '',
        name: 'RichMarker ',
    },
    DISTANCETOOL: {
        version: '1.2',
        cssVersion: '',
        name: 'DistanceTool ',
    },
    CITYLIST: {
        version: '1.4',
        cssVersion: '',
        name: 'CityList ',
    },
}

export type loadLibEnums = 'TextIconOverlay' | 'InfoBox' | 'LuShu' | 'CurveLine' | 'MarkerClusterer' | 
    'DrawingManager' | 'Heatmap' | 'TrafficControl' | 'GeoUtils' | 'RichMarker' | 'DistanceTool' |
    'CityList';
export type loadLibName = string | string[];
export type loadScriptConfig = {
    script: string,
    urls: string[],
}

export const getScriptUrl = (scriptName: string, version: string) => {
    return defaultUrl.replaceAll('$name', scriptName).replaceAll('$version', version);
}

export const getCssUrl = (scriptName: string, version: string) => {
    return defaultCssUrl.replaceAll('$name', scriptName).replaceAll('$version', version);
}

export const getLoadScriptObject = (script: string) => {
    const library = loadLibInfo[script.toUpperCase()] || null;
    const scriptName = library?.name;
    if (!library) {
        throw new Error(`loadLibName: ${scriptName} not found`);
    } else {
        const result = [getScriptUrl(scriptName, library.version)];
        const css = library.cssVersion ? getCssUrl(scriptName, library.cssVersion) : '';
        css && result.push(css);
        return {
            script: scriptName,
            urls: result
        };
    }
}