import { service, NODE_ENV } from "@/axios/service";
import { config } from "@/axios/config";
import { store } from '../store';
import { useUserStore } from '@/store/user'
const { default_headers } = config
const userStore = useUserStore(store);

const request = (option: any) => {
    const { url, method, params, data, headersType, responseType, absolutelyUrl } = option
    return service({
        url: url,
        method,
        params,
        data,
        headers: {
            'Content-Type': headersType || default_headers,
            'token': userStore.token
        },
        absolutelyUrl,
        responseType: responseType
    } as any)
}

export default {
    get: <T = any>(option: any) => {
        return request({ method: 'get', ...option }) as unknown as T
    },
    post: <T = any>(option: any) => {
        return request({ method: 'post', ...option }) as unknown as T
    },
    head: <T = any>(option: any) => {
        return request({ method: 'head', ...option }) as unknown as T
    },
    delete: <T = any>(option: any) => {
        return request({ method: 'delete', ...option }) as unknown as T
    },
    put: <T = any>(option: any) => {
        return request({ method: 'put', ...option }) as unknown as T
    },
}