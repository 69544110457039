import JSEncrypt from 'jsencrypt'
import { publicEncryptKey } from "../../../utils/publicKeys";

export function getToken(data: string) {
    const token = data.split(';')[1] || ''
    return token
}

export function getPassword(pw: string) {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicEncryptKey);
    const rsaPassWord = encryptor.encrypt(pw);
    return rsaPassWord
}
