import { defineStore } from 'pinia';
import { MenuItem } from '#/userMenu';

export interface UserState {
    userName?: string;
    token?: string;
    avatar?: string;
    permissions?: any[];
    userMenu?: MenuItem | null;
    isDark?: boolean;
}

export const useUserStore = defineStore('user', {
    state: (): UserState => ({
        userName: '',
        token: '',
        avatar: '',
        permissions: [],
        userMenu: null,
        isDark: false,
    }),
    getters: {
        isLogin(state: UserState): boolean {
            return state.token ? true : false
        },
        getInfo(state: UserState): UserState {
            return { ...state };
        },
        getPermissionsList(state: UserState): String[] {
            //todo
            //读取扁平化权限数组
            const { permissions } = state;
            const permissionIds: string[] = [];
            permissions?.forEach((item: any) => {
                permissionIds.push(item.permissionId);
                //寻找动作权限
                const { actionEntitySet } = item;
                if (actionEntitySet && actionEntitySet.length > 0) {
                    actionEntitySet.forEach(action => {
                        permissionIds.push(action.id);
                    });
                }
            })
            return permissionIds
        }
    },
    actions: {
        LOAD_LOCAL_STORAGE() {
            const userState: UserState = JSON.parse(localStorage.getItem('userState') || '{}');
            this.$state = {
                ...this.$state,
                ...userState
            }
        },
        //更改所有用户数据
        setUserStateEntier(data: UserState) {
            this.SET_USER_STATE_ENTIER(data)
        },
        SET_USER_STATE_ENTIER(data: UserState) {
            const keys = Object.keys(data);
            for (const key of keys) {
                this[key] = data[key];
            }
        },
        //更改单项用户数据
        setUserState(data) {
            this.SET_USER_STATE(data)
        },
        SET_USER_STATE({ key, value }) {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                this[key] = value;
            }
        },
        //设置token并缓存至本地
        SET_TOKEN(token: string) {
            this.token = token;
            const userInfo = JSON.parse(localStorage.getItem('userState') || '{}');
            userInfo.token = token;
            localStorage.setItem('userState', JSON.stringify(userInfo));
        },
        //设置menu并缓存至sessionStorage
        SET_SESSION(menu: MenuItem) {
            this.userMenu = menu;
            sessionStorage.setItem('userMenu', JSON.stringify(menu));
        },
        logout() {
            this.setUserStateEntier({
                userName: '',
                token: '',
                avatar: '',
                permissions: [],
                userMenu: null,
            });
            localStorage.removeItem('userState');
        }
    },
});
