import {createApp} from 'vue';
import {MotionPlugin} from '@vueuse/motion'
import {router} from './route/'
import {setupStore} from "./store";
import App from './App.vue';
import "element-plus/theme-chalk/el-loading.css";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-notification.css";
import "element-plus/theme-chalk/el-message-box.css";
// import "./style/icon.css"
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
//import { api } from './axios'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
const app = createApp(App);

import loadMore from '@/directives/loadMore/index'
import action from '@/directives/action/index'


// 注册element-plus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
setupStore(app);
app.use(router).use(MotionPlugin);
app.use(VueVirtualScroller);
app.directive('loadMore', loadMore)
app.directive('action', action)
//app.config.globalProperties.$api = api;
app.mount('#app');

